export const typeColor = {
    normal: "rgb(159, 161, 159)",
    fighting: "rgb(255, 128, 0)",
    flying: "rgb(129, 185, 239)",
    poison: "rgb(145, 65, 203)",
    ground: "rgb(145, 81, 33)",
    rock: "rgb(175, 169, 129)",
    bug: "rgb(145, 161, 25)",
    ghost: "rgb(112, 65, 112)",
    steel: "rgb(96, 161, 184)",
    fire: "rgb(230, 40, 41)",
    water: "rgb(41, 128, 239)",
    grass: "rgb(63, 161, 41)",
    electric: "rgb(250, 192, 0)",
    psychic: "rgb(239, 65, 121)",
    ice: "rgb(61, 206, 243)",
    dragon: "rgb(61, 206, 243)",
    dark: "rgb(98, 77, 78)",
    fairy: "rgb(239, 112, 239)",
}

export const typeDisplay = {
    normal: "Normal",
    fighting: "Fighting",
    flying: "Flying",
    poison: "Poison",
    ground: "Ground",
    rock: "Rock",
    bug: "Bug",
    ghost: "Ghost",
    steel: "Steel",
    fire: "Fire",
    water: "Water",
    grass: "Grass",
    electric: "Electric",
    psychic: "Psychic",
    ice: "Ice",
    dragon: "Dragon",
    dark: "Dark",
    fairy: "Fairy",
}

export const catDisplay = {
    physical: "Physical",
    special: "Special",
    status: "Status",
}

export const statColor = {
    hp: "rgb(105, 220, 18)",
    atk: "rgb(239, 204, 24)",
    def: "rgb(232, 100, 18)",
    satk: "rgb(20, 195, 241)",
    sdef: "rgb(74, 106, 223)",
    spd: "rgb(213, 29, 173)",
}

export const statDisplay = {
    hp: "HP",
    atk: "Attack",
    def: "Defense",
    satk: "Sp. Attack",
    sdef: "Sp. Defense",
    spd: "Speed",
}
export const statDisplayShort = {
    hp: "HP",
    atk: "Attack",
    def: "Defense",
    satk: "Sp. Atk",
    sdef: "Sp. Def",
    spd: "Speed",
}
